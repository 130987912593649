import React from "react"
import { useState, useEffect } from "react"
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/free-solid-svg-icons"
import staticSignatures from "../data/signatures.json";

export const SignatureList = props => {
  const { shouldUpdateSignatures, setShouldUpdateSignatures } = props

  const [signatures, setSignatures] = useState(staticSignatures)
  const [signatureCount, setSignatureCount] = useState(staticSignatures.length)
  const [loading, setLoading] = useState(false)

  const getSignatures = async () => {
    setLoading(true)
    try {
      const { data: signatures } = await axios.get(
        process.env.GATSBY_API_URL + "/signatures"
      )
      setSignatures(signatures.reverse())
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const getSignatureCount = async () => {
    try {
      const { data } = await axios.get(
        process.env.GATSBY_API_URL + "/signatures/count"
      )
      setSignatureCount(data)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!shouldUpdateSignatures) {
      return
    }
    setShouldUpdateSignatures(false)
    getSignatures()
    getSignatureCount()
  }, [shouldUpdateSignatures, setShouldUpdateSignatures])

  return (
    <>
      <h3>
        <FontAwesomeIcon icon={faUsers} /> Signatures ({signatureCount})
      </h3>
      {loading === true ? (
        <p>Loading...</p>
      ) : (
        <ul style={{ listStyleType: "none" }}>
          {signatures.map((signature, i) => (
            <li
              style={{ borderBottom: "1px dashed #AAA", padding: "5px" }}
              key={i}
            >
              <span
                className={`signature signature-${Math.ceil(
                  Math.random() * 3
                )}`}
              >
                {signature.name}
              </span>
              <br />
              <span className="demographic">
                {signature.city} {signature.state} {signature.country}
              </span>
            </li>
          ))}
        </ul>
      )}
    </>
  )
}
